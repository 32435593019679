import { createSlice } from '@reduxjs/toolkit'

const initialCourseState = {
	listLoading: false,
	actionsLoading: false,
	totalCount: 0,
	entities: [],
	lastError: null,
	course: undefined,
	paymentMode: '',
	slugTotalCount: 0,
	slugs: [],
}
export const callTypes = {
	list: 'list',
	action: 'action',
}

export const courseSlice = createSlice({
	name: 'course',
	initialState: initialCourseState,
	reducers: {
		catchError: (state, action) => {
			state.error = `${action.type}: ${action.payload.error}`
			if (action.payload.callType === callTypes.list) {
				state.listLoading = false
			} else {
				state.actionsLoading = false
			}
		},
		startCall: (state, action) => {
			state.error = null
			if (action.payload.callType === callTypes.list) {
				state.listLoading = true
			} else {
				state.actionsLoading = true
			}
		},
		coursesFetched: (state, action) => {
			const { totalCount, entities } = action.payload
			state.listLoading = false
			const newArray = state.entities.concat(entities)
			state.entities = newArray
			state.totalCount = totalCount
			state.error = null
		},
		filteredCoursesFetched: (state, action) => {
			const { totalCount, entities } = action.payload
			state.listLoading = false
			state.entities = entities
			state.totalCount = totalCount
			state.error = null
		},
		courseFetched: (state, action) => {
			state.listLoading = false
			state.course = action.payload.data
			state.error = null
		},
		paymentModeFetched: (state, action) => {
			state.paymentMode = action.payload.paymentMode
			state.error = null
		},
		slugsFetched: (state, action) => {
			const { totalCount, entities } = action.payload
			state.listLoading = false
			state.slugs = entities
			state.slugTotalCount = totalCount
			state.error = null
		},
	},
})
