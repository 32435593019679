import { createSlice } from '@reduxjs/toolkit'
import {
	getStorage,
	setStorage,
	removeStorage,
} from '../../../../_metronic/_helpers/LocalStorageHelpers'

let cartItemsFromLocalStorage = getStorage('cartItems')
	? JSON.parse(getStorage('cartItems'))
	: []

let cartTotalFromLocalStorage = 0
if (getStorage('cartItems')) {
	JSON.parse(getStorage('cartItems')).map(
		(data) => (cartTotalFromLocalStorage += data.price),
	)
}
const initialCartState = {
	listLoading: false,
	actionsLoading: false,
	cartTotal: cartTotalFromLocalStorage,
	cartItems: cartItemsFromLocalStorage,
	lastError: null,
	cartItem: {},
}
export const callTypes = {
	list: 'list',
	action: 'action',
}

export const cartSlice = createSlice({
	name: 'cart',
	initialState: initialCartState,
	reducers: {
		catchError: (state, action) => {
			state.error = `${action.type}: ${action.payload.error}`
			if (action.payload.callType === callTypes.list) {
				state.listLoading = false
			} else {
				state.actionsLoading = false
			}
		},
		startCall: (state, action) => {
			state.error = null
			if (action.payload.callType === callTypes.list) {
				state.listLoading = true
			} else {
				state.actionsLoading = true
			}
		},
		cartItemsFetched: (state, action) => {
			const { item } = action.payload
			let existed_item = state.cartItems.find((data) => data.slug === item.slug)
			if (existed_item) {
				state.cartItems = [...state.cartItems]
			} else {
				state.cartItems = [...state.cartItems, item]
				state.cartTotal += item.price
			}
			setStorage('cartItems', JSON.stringify(state.cartItems), 1314000)
		},
		cartItemFetched: (state, action) => {
			state.listLoading = false
			state.cartItem = action.payload.cartItem
			state.error = null
		},
		removeItemFromCart: (state, action) => {
			state.cartItems = state.cartItems.filter(
				(item) => item.slug !== action.payload.item.slug,
			)
			state.cartTotal -= action.payload.item.price
			setStorage('cartItems', JSON.stringify(state.cartItems), 1314000)
		},
		clearCartArray: (state, action) => {
			state.cartItems = []
			state.cartTotal = 0
			removeStorage('cartItems')
		},
	},
})
