import axios from 'axios'

export const SETTINGS_URL = process.env.REACT_APP_API_URL + '/student/settings'

export function fetchBusinessSettings() {
	return axios.get(`${SETTINGS_URL}`)
}

export function fetchSlugs() {
	return axios.get(`${SETTINGS_URL}/businesses`)
}
// export function findSettings(queryParams) {
//   if(queryParams){
//     return axios.get(`${SETTINGS_URL}/?filter=${JSON.stringify(queryParams)}`);
//   }else{
//     return axios.get(`${SETTINGS_URL}`);
//   }
// }
