import React from 'react'
import { toAbsoluteUrl } from '../../../components/AssetsHelpers'

function ErrorPage({ history }) {
	return (
		<>
			{/* <!-- error --> */}
			<div className='min-vh-100 d-flex align-items-center '>
				<div className='container'>
					<div className='row align-items-center p-lg-12'>
						<div className='col-lg-4 col-12'>
							<h1 className='display-3 mb-3 font-weight-bold '>Oops!</h1>
							<p className='mb-4'>
								Sorry, we couldn’t find the page you were looking for. Go ahead
								and try the button at the bottom to find your way!
							</p>
							<span onClick={history.goBack} className='btn btn-primary'>
								Back to safety
							</span>
						</div>
						<div className='offset-lg-1 col-lg-7 col-12 text-center'>
							<img
								src={toAbsoluteUrl('/assets/images/error-img.png')}
								alt=''
								className='img-fluid'
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default ErrorPage
