import { all } from 'redux-saga/effects'
import { combineReducers } from 'redux'

import * as auth from '../app/modules/Auth/_redux/authRedux'
import { businessSlice } from '../app/modules/Business/_redux/businessSlice'
import { cartSlice } from '../app/modules/Cart/_redux/cartSlice'
import { courseSlice } from '../app/modules/Courses/_redux/courseSlice'
import { profileSlice } from '../app/modules/Profiles/_redux/profileSlice'
import { programSlice } from '../app/modules/Programs/_redux/programSlice'
import { settingsSlice } from '../app/modules/Settings/_redux/settingsSlice'
import { enrolmentSlice } from '../app/modules/MyLibrary/_redux/EnrolmentSlice'
import { pagesSlice } from '../app/modules/Configure/_redux/pagesSlice'
import { landingSlice } from '../app/modules/Landing/_redux/landingSlice'
import { subscriptionsSlice } from '../app/modules/Profiles/_redux/subscriptions/subscriptionsSlice'
import { jobSlice } from '../app/modules/Jobs/_redux/jobSlice'

export const rootReducer = combineReducers({
	auth: auth.reducer,
	cart: cartSlice.reducer,
	courses: courseSlice.reducer,
	profiles: profileSlice.reducer,
	programs: programSlice.reducer,
	jobs: jobSlice.reducer,
	settings: settingsSlice.reducer,
	business: businessSlice.reducer,
	enrolments: enrolmentSlice.reducer,
	pages: pagesSlice.reducer,
	landing: landingSlice.reducer,
	subscriptions: subscriptionsSlice.reducer,
	// business: subscriptionsSlice.reducer,
})

export function* rootSaga() {
	yield all([auth.saga()])
}
