/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Link } from 'react-router-dom'

export function SubscriptionModal() {
	return (
		<div
			className='modal fade'
			id='SubscriptionModal'
			tabIndex='-1'
			role='dialog'
			aria-labelledby='SubscriptionModalTitle'
			aria-hidden='true'
		>
			<div
				className='modal-dialog modal-lg modal-dialog-centered'
				role='document'
			>
				<div className='modal-content'>
					<div className='modal-header'>
						<h3
							className='modal-title m-auto ml-10'
							id='SubscriptionModalTitle'
						>
							You don't have any valid Subscription
						</h3>
						<button
							type='button'
							className='close'
							data-dismiss='modal'
							aria-label='Close'
							id='closeSubscriptionModal'
						>
							<span aria-hidden='true'>&times;</span>
						</button>
					</div>
					<div className='modal-body'>
						<div className='row'>
							<center>
								<Link
									to='/profile/subscription'
									className='btn btn-primary m-2 font-weight-bolder'
									onClick={() =>
										document.getElementById('closeSubscriptionModal').click()
									}
								>
									Proceed to Subscription Page
								</Link>
								<Link
									to='/profile/subscription?payment_code=PAYMENT_CODE'
									className='btn btn-primary m-2 font-weight-bolder'
									onClick={() =>
										document.getElementById('closeSubscriptionModal').click()
									}
								>
									Complete Subscription with Payment Code
								</Link>
							</center>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
