import { createSlice } from '@reduxjs/toolkit'

const initialBusinessState = {
	listLoading: false,
	actionsLoading: false,
	totalCount: 0,
	lastError: null,
	entities: {},
}
export const callTypes = {
	list: 'list',
	action: 'action',
}

export const businessSlice = createSlice({
	name: 'business',
	initialState: initialBusinessState,
	reducers: {
		catchError: (state, action) => {
			state.error = `${action.type}: ${action.payload.error}`
			if (action.payload.callType === callTypes.list) {
				state.listLoading = false
			} else {
				state.actionsLoading = false
			}
		},
		startCall: (state, action) => {
			state.error = null
			if (action.payload.callType === callTypes.list) {
				state.listLoading = true
			} else {
				state.actionsLoading = true
			}
		},
		businessDataFetched: (state, action) => {
			const { business } = action.payload
			state.listLoading = false
			state.entities = business
			state.error = null
		},
	},
})
