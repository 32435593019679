import { createSlice } from '@reduxjs/toolkit'

const initialJobState = {
	listLoading: false,
	actionsLoading: false,
	totalCount: 0,
	entities: [],
	lastError: null,
	job: undefined,
	fields: [],
	metadata: [],
}
export const callTypes = {
	list: 'list',
	action: 'action',
}

export const jobSlice = createSlice({
	name: 'job',
	initialState: initialJobState,
	reducers: {
		catchError: (state, action) => {
			state.error = `${action.type}: ${action.payload.error}`
			if (action.payload.callType === callTypes.list) {
				state.listLoading = false
			} else {
				state.actionsLoading = false
			}
		},
		startCall: (state, action) => {
			state.error = null
			if (action.payload.callType === callTypes.list) {
				state.listLoading = true
			} else {
				state.actionsLoading = true
			}
		},
		jobsFetched: (state, action) => {
			const { totalCount, entities } = action.payload
			state.listLoading = false
			state.entities = entities
			state.totalCount = totalCount
			state.error = null
		},
		fieldsFetched: (state, action) => {
			const { entities } = action.payload
			state.listLoading = false
			state.fields = entities
		},
		metadataFetched: (state, action) => {
			const { entities } = action.payload
			state.listLoading = false
			state.metadata = entities
		},
		jobFetched: (state, action) => {
			state.actionsLoading = false
			state.job = action.payload.data
			state.error = null
		},
	},
})
