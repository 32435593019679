import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { put, takeLatest } from 'redux-saga/effects'
import { getUserByToken } from './authCrud'

export const actionTypes = {
	Login: '[Login] Action',
	Logout: '[Logout] Action',
	UserRequested: '[Request User] Action',
	UserLoaded: '[Load User] Auth API',
}

const initialAuthState = {
	user: undefined,
	authToken: undefined,
	refreshToken: undefined,
}

export const reducer = persistReducer(
	{
		storage,
		key: process.env.REACT_APP_LOCAL_STORAGE_VARIABLE,
		whitelist: ['user', 'authToken', 'refreshToken'],
	},
	(state = initialAuthState, action) => {
		switch (action.type) {
			case actionTypes.Login: {
				return { ...state, ...action.payload }
			}

			case actionTypes.Logout: {
				return initialAuthState
			}

			case actionTypes.UserLoaded: {
				const { user } = action.payload
				return { ...state, user }
			}

			default:
				return state
		}
	}
)

export const actions = {
	login: (data) => ({
		type: actionTypes.Login,
		payload: data,
	}),
	logout: () => ({ type: actionTypes.Logout }),
	requestUser: (user) => ({
		type: actionTypes.UserRequested,
		payload: { user },
	}),
	fulfillUser: (user) => ({
		type: actionTypes.UserLoaded,
		payload: { user },
	}),
}

export function* saga() {
	yield takeLatest(actionTypes.Login, function* loginSaga() {
		yield put(actions.requestUser())
	})

	yield takeLatest(actionTypes.UserRequested, function* userRequested() {
		const data = yield getUserByToken()
		yield put(actions.fulfillUser(data))
	})
}
