import axios from 'axios'

export const API_URL = process.env.REACT_APP_STUDENT_API_URL + '/subscriptions'
export const PLAN_API_URL = process.env.REACT_APP_STUDENT_API_URL + '/plans'
export const ENROLMENT_API_URL =
	process.env.REACT_APP_STUDENT_API_URL + '/enrolments'

// READ
export function getAllPlans(queryParams) {
	return axios.get(
		PLAN_API_URL +
			(queryParams ? `?queryParams=${JSON.stringify(queryParams)}` : '')
	)
}
export function findSubscriptions(queryParams = {}) {
	return axios.get(`${API_URL}/?queryParams=${JSON.stringify(queryParams)}`)
}
// READ
export function getAllMySubscriptions(queryParams = {}) {
	return axios.get(
		API_URL + '/personal?queryParams=' + JSON.stringify(queryParams)
	)
}
export function getAlumniPlan() {
	return axios.get(PLAN_API_URL + '/alumni')
}
export function getUpgradePlan() {
	return axios.get(PLAN_API_URL + '/upgrade')
}
export function getAllMyEnrolments(queryParams = {}) {
	return axios.get(
		ENROLMENT_API_URL + '/all?queryParams=' + JSON.stringify(queryParams)
	)
}
export function updateEnrolment(data) {
	return axios.put(ENROLMENT_API_URL, data)
}
// export function getMySubscription(queryParams) {
// 	return axios.get(API_URL + `/personal/${queryParams}`)
// }
export function subscribe(data) {
	return axios.post(API_URL + '/subscribe', data)
}
export function createSubscriber(data) {
	return axios.get(API_URL + '/subscriber?sessionId=' + data)
}
export function openCheckSection(data) {
	return axios.post(API_URL + '/create-checkout-session', data)
}
export function manageBilling() {
	return axios.get(API_URL + '/manage_billing')
}
export function validatePaymentCode(data) {
	return axios.post(API_URL + '/validate_payment_code', data)
}
export function checkPaymentCode(data) {
	return axios.get(API_URL + '/check_payment_code', data)
}
export function cancelSubscription() {
	return axios.delete(API_URL)
}

export function findTaxrates(queryParams = {}) {
	return axios.get(
		`${
			process.env.REACT_APP_STUDENT_API_URL + '/taxrates'
		}/?queryParams=${JSON.stringify(queryParams)}`
	)
}
export function findOpenTaxrates(queryParams = {}) {
	return axios.get(
		`${
			process.env.REACT_APP_API_URL + '/taxrates'
		}/?queryParams=${JSON.stringify(queryParams)}`
	)
}
