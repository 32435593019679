import * as requestFromServer from './profileCrud'
import { profileSlice, callTypes } from './profileSlice'

const { actions } = profileSlice

export const fetchTutors = (queryParams) => async (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.list }))
	try {
		const response = await requestFromServer.fetchTutors(queryParams)
		dispatch(actions.tutorsFetched({ ...response }))
	} catch (error) {
		dispatch(actions.catchError({ error, callType: callTypes.list }))
		throw error
	}
}
export const fetchTutors1 = (queryParams) => async (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.list }))
	try {
		const response = await requestFromServer.fetchTutors1(queryParams)
		dispatch(actions.tutorsFetched({ ...response }))
	} catch (error) {
		dispatch(actions.catchError({ error, callType: callTypes.list }))
		throw error
	}
}
export const fetchProfiles = (query) => async (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.list }))
	try {
		const response = await requestFromServer.fetchProfiles(query)
		dispatch(actions.profilesFetched({ ...response }))
	} catch (error) {
		dispatch(actions.catchError({ error, callType: callTypes.list }))
		throw error
	}
}

export const fetchMyProfile = () => async (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.list }))
	try {
		const data = await requestFromServer.fetchProfile()
		dispatch(actions.profileFetched({ data }))
	} catch (error) {
		dispatch(actions.catchError({ error, callType: callTypes.list }))
		throw error
	}
}
