import axios from 'axios'

export const API_URL = process.env.REACT_APP_API_URL + '/student/profile'

export function fetchProfiles() {
	return axios.get(`${process.env.REACT_APP_API_URL}/student/users/tutors`)
}

export function fetchProfile() {
	return axios.get(`${API_URL}`)
}

export function fetchAuthorProfile(businessSlug, tutorSlug) {
	return axios.get(`${API_URL}/businesses/${businessSlug}/${tutorSlug}/`)
}

export function fetchTutors(queryParams = {}) {
	return axios.get(
		`${
			process.env.REACT_APP_API_URL
		}/student/tutors?queryParams=${JSON.stringify(queryParams)}`
	)
}
export function fetchTutors1(queryParams = {}) {
	return axios.get(
		`${process.env.REACT_APP_API_URL}/tutors?queryParams=${JSON.stringify(
			queryParams
		)}`
	)
}
