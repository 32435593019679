import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import * as auth from '../_redux/authRedux'
import * as authCrud from '../_redux/authCrud'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'

/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
	username: '',
	password: '',
}

function Login(props) {
	const dispatch = useDispatch()
	let { status, auth_token, refresh_token } = useParams()
	const [businesses, setBusinesses] = useState()
	const [selected, setSelected] = useState()
	const [token, setToken] = useState()

	// const { intl } = props;
	const [loading, setLoading] = useState(false)
	const LoginSchema = Yup.object().shape({
		username: Yup.string().required('Username is required'),
		password: Yup.string().required('Password is required'),
	})

	const loadToState = (authToken, refreshToken) => {
		dispatch(auth.actions.login({ authToken, refreshToken }))
	}
	const formik = useFormik({
		initialValues,
		validationSchema: LoginSchema,
		onSubmit: async (values, { setStatus, setSubmitting }) => {
			setLoading(true)
			try {
				const data = await authCrud.login({
					username: values.username,
					password: values.password,
				})
				if (data.auth_token && data.refresh_token) {
					loadToState(data.auth_token, data.refresh_token)
				} else if (data.business_list && data.token) {
					setBusinesses(data.business_list)
					setToken(data.token)
				} else throw new Error('Unable to Login')
				setStatus()
			} catch (error) {
				setStatus(
					error.response.data.message || 'The login detail is incorrect'
				)
				setSubmitting(false)
				setLoading(false)
			}
		},
	})

	const businessSelected = async (business_id) => {
		try {
			setLoading(true)
			setSelected(business_id)
			const data = await authCrud.switchBusiness({ token, business_id })
			loadToState(data.auth_token, data.refresh_token)
			formik.setStatus()
		} catch (error) {
			formik.setStatus(
				error.response.data.message || 'The login detail is incorrect'
			)
			setSelected(undefined)
			setLoading(false)
		}
	}

	const getInputClasses = (fieldname) => {
		if (formik.touched[fieldname] && formik.errors[fieldname]) {
			return 'is-invalid'
		}

		if (formik.touched[fieldname] && !formik.errors[fieldname]) {
			return 'is-valid'
		}

		return ''
	}

	useEffect(() => {
		if (status === '1' && auth_token && refresh_token) {
			loadToState(
				decodeURIComponent(auth_token),
				decodeURIComponent(refresh_token)
			)
		}
		// eslint-disable-next-line
	}, [status, auth_token, refresh_token])

	return (
		<div
			className='min-vh-100 d-flex align-items-center '
			style={{
				background: `url(/assets/images/register-img.jpg)no-repeat`,
				backgroundSize: 'cover',
			}}
		>
			<div className='container'>
				<div className='row'>
					<div className='offset-lg-3 col-lg-6 col-12'>
						<Link to='/' className='mb-4 d-flex justify-content-center'>
							<img
								src='/assets/images/logo-white.png'
								alt=''
								width='150px'
								style={{ marginTop: '-10px' }}
							/>
						</Link>

						<div className='bg-dark p-4 p-lg-8 rounded-lg'>
							{businesses ? (
								<>
									<h1 className='mb-2 text-white'>Welcome</h1>
									<p className='mb-4'>Select Your Business.</p>
									{formik.status ? (
										<div
											className='alert alert-danger alert-dismissible fade show'
											role='alert'
										>
											<div className='alert-text font-weight-bold'>
												{formik.status}
											</div>
										</div>
									) : (
										''
									)}
									<div className='card-body pt-1'>
										{businesses.map((business, key) => (
											<button
												type='button'
												className='btn btn-primary btn-lg btn-block'
												style={{ textAlign: 'left' }}
												onClick={() =>
													!selected && businessSelected(business.id)
												}
												key={key}
												disabled={selected === business.id}
											>
												{selected === business.id && loading && (
													<span
														className='spinner-border spinner-border-sm mr-2'
														role='status'
														aria-hidden='true'
													></span>
												)}
												{business.name}
											</button>
											// <div
											// 	onClick={() =>
											// 		!selected && businessSelected(business.id)
											// 	}
											// 	key={key}
											// 	className='d-flex align-items-center mb-3 bg-light-primary bg-hover-primary rounded-sm p-3 text-dark text-hover-light'
											// >
											// 	{/*begin::Symbol*/}
											// 	{/* <div className='symbol symbol-40 me-5'> */}
											// 		<span className='symbol-label'>
											// 			{selected === business.id && loading ? (
											// 				<span className='spinner-border spinner-border-sm align-middle'></span>
											// 			) : (
											// 				<i className='fas fa-solid fa-buildings'></i>
											// 			)}
											// 		</span>
											// 	</div>
											// 	{/*end::Symbol*/}
											// 	{/*begin::Text*/}
											// 	<div className='d-flex flex-column font-weight-bold'>
											// 		<span
											// 			// href='#'
											// 			className='fw-bold text-capitalize'
											// 		>
											// 			{business.name}
											// 		</span>
											// 		{/* <span className='text-muted'>Project Manager</span> */}
											// 	</div>
											// 	{/*end::Text*/}
											// </div>
										))}
									</div>
								</>
							) : (
								<form
									onSubmit={formik.handleSubmit}
									className='form fv-plugins-bootstrap fv-plugins-framework'
								>
									<h1 className='mb-2 text-white'>Welcome</h1>
									<p className='mb-4'>
										Sign in using your SingleLogin.io credentials.
									</p>
									{formik.status ? (
										<div
											className='alert alert-danger alert-dismissible fade show'
											role='alert'
										>
											<div className='alert-text font-weight-bold'>
												{formik.status}
											</div>
										</div>
									) : (
										''
									)}
									<div className='mb-3'>
										<label className='form-label text-white-50'>Username</label>
										<input
											placeholder=''
											type='text'
											className={`form-control border-0 ${getInputClasses(
												'username'
											)}`}
											{...formik.getFieldProps('username')}
										/>
										{/* <input type="email" id="email" className="form-control border-0" placeholder="Email address" required="" /> */}
									</div>
									<div className='mb-3'>
										<label className='form-label text-white-50'>Password</label>
										<input
											placeholder=''
											type='password'
											className={`form-control border-0 ${getInputClasses(
												'password'
											)}`}
											{...formik.getFieldProps('password')}
										/>
										{/* <input type="email" id="email" className="form-control border-0" placeholder="Email address" required="" /> */}
									</div>

									<div className='text-center'>
										<button
											className='btn btn-primary btn-block'
											type='submit'
											disabled={loading}
										>
											Sign in
											{loading && (
												<div
													className='spinner-border spinner-border-sm ml-2'
													role='status'
												>
													<span className='sr-only'>Loading...</span>
												</div>
											)}
										</button>
									</div>
								</form>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default connect(null, auth.actions)(Login)
