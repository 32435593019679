import React, { useEffect, useState } from "react";

function Base() {
  const [loading, setLoading] = useState(false);
  const [couponValue, setCouponValue] = useState(0);

  const [authType, setAuthType] = useState("username");
  const [authInput, setAuthInput] = useState("");
  const [emailInput, setEmailInput] = useState("");
  const [phoneInput, setPhoneInput] = useState("");
  const [contactConsent, setContactConsent] = useState(false);

  const handleAuthTypeChange = (event) => {
    setAuthType(event.target.value);
  };

  const handleSubmit = () => {
    if (authType === "coupon") {
      if (!emailInput || !phoneInput || !contactConsent) {
        alert("Please fill in all fields and agree to be contacted.");
        return;
      }
      // Call the endpoint for affiliate coupon code
      console.log("Calling endpoint for coupon code", {
        authInput,
        emailInput,
        phoneInput,
        contactConsent,
      });
    } else {
      // Call the endpoint for Blackforce username
      console.log("Calling endpoint for username", { authInput });
    }
  };

  const formik = () => {};

  useEffect(() => {
    document.getElementById("splashScreenButton").click();
    // }
  }, []);

  return (
    <>
      <div style={{ minHeight: "80vh" }}></div>
      <div>
        <button
          type="button"
          data-toggle="modal"
          data-target="#SplashScreenModal"
          id="splashScreenButton"
          hidden
        ></button>
        <div
          className="modal fade"
          id="SplashScreenModal"
          tabIndex="-1"
          aria-labelledby="SplashScreenModalLabel"
          aria-hidden="true"
          data-backdrop="static"
          data-keyboard="false"
          style={{ background: "#261f44!important" }}
        >
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title m-auto" id="SplashScreenModalLabel">
                  BlackForce Events
                </h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  hidden
                  id="SplashScreenModalClose"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body mx-5" style={{ textAlign: "justify" }}>
                <center>
                  <p>
                    Please enter your Blackforce Username or a referral code
                    (affiliate coupon code)
                  </p>
                </center>

                <div className="row">
                  <center>
                    <div className="mb-3 col-md-8 col-12">
                      <div className="d-flex justify-content-center">
                        <div className="form-check">
                          <input
                            type="radio"
                            className="form-check-input"
                            name="authType"
                            id="usernameOption"
                            value="username"
                            onChange={handleAuthTypeChange}
                            checked={authType === "username"}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="usernameOption"
                          >
                            Blackforce Username
                          </label>
                        </div>
                        <div className="form-check ml-3">
                          <input
                            type="radio"
                            className="form-check-input"
                            name="authType"
                            id="couponOption"
                            value="coupon"
                            onChange={handleAuthTypeChange}
                            checked={authType === "coupon"}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="couponOption"
                          >
                            Affiliate Coupon Code
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="mb-3 col-md-8 col-12">
                      <div className="d-flex">
                        <input
                          type="text"
                          id="authInput"
                          className="form-control"
                          placeholder="Blackforce Username or Coupon Code"
                          value={authInput}
                          onChange={(e) => setAuthInput(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    {authType === "coupon" && (
                      <div
                        className="mb-3 col-md-8 col-12"
                        id="additionalFields"
                      >
                        <div className="d-flex">
                          <input
                            type="email"
                            id="emailInput"
                            className="form-control"
                            placeholder="Email"
                            value={emailInput}
                            onChange={(e) => setEmailInput(e.target.value)}
                            required
                          />
                        </div>
                        <div className="d-flex mt-3">
                          <input
                            type="tel"
                            id="phoneInput"
                            className="form-control"
                            placeholder="Phone Number"
                            value={phoneInput}
                            onChange={(e) => setPhoneInput(e.target.value)}
                            required
                          />
                        </div>
                        <div className="form-check mt-3">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="contactConsent"
                            checked={contactConsent}
                            onChange={(e) =>
                              setContactConsent(e.target.checked)
                            }
                            required
                          />
                          <label
                            className="form-check-label"
                            htmlFor="contactConsent"
                          >
                            I agree to be contacted by Blackforce for feedback
                            on the master class event.
                          </label>
                        </div>
                      </div>
                    )}
                    <div className="mb-3 col-md-8 col-12">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleSubmit}
                      >
                        Submit
                      </button>
                    </div>
                  </center>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Base;
