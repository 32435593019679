import React from 'react'
import { Link } from 'react-router-dom'

export function Footer() {
	return (
		<>
			{/*  footer */}
			<div className='footer pt-11 pb-3 bg-dark text-base'>
				<div className='container'>
					<div className='row'>
						<div className='col-lg-2 col-md-4 col-6'>
							<div className='mb-4'>
								<h4 className='mb-4 text-white'>About</h4>
								<ul className='list-unstyled list-group'>
									<li className='list-group-item'>
										<Link to='/team' className='list-group-item-link'>
											Our Team
										</Link>
									</li>
									<li className='list-group-item'>
										<Link to='/contact' className='list-group-item-link'>
											Contact Us
										</Link>
									</li>
									<li className='list-group-item'>
										<Link to='/program' className='list-group-item-link'>
											 Programs
										</Link>
									</li>
									<li className='list-group-item'>
										<Link to='/course' className='list-group-item-link'>
											 Courses
										</Link>
									</li>
								</ul>
							</div>
						</div>
						<div className='col-lg-2 col-md-4 col-6'>
							<div className='mb-4'>
								<h4 className='mb-4 text-white '>Resources</h4>
								<ul className='list-unstyled list-group '>
									<li className='list-group-item'>
										<a
											href='https://salespage.blackforce.ca'
											className='list-group-item-link'
										>
											Join BlackForce
										</a>
									</li>
									<li className='list-group-item'>
										<Link to='/enrolment' className='list-group-item-link'>
											Enrolment Instruction
										</Link>
									</li>
									<li className='list-group-item'>
										<Link to='/terms' className='list-group-item-link'>
											Terms & Conditions
										</Link>
									</li>
									<li className='list-group-item'>
										<Link to='/faq' className='list-group-item-link'>
											FAQs
										</Link>
									</li>
								 
								</ul>
							</div>
						</div>
						<div className='col-lg-2 col-md-4 col-6'>
							<div className='mb-4'>
								<h4 className='mb-4 text-white '>Applications</h4>
								<ul className='list-unstyled list-group '>
									<li className='list-group-item'>
										<a href='https://blackforce.ca/library' className='list-group-item-link'>
											Access Videos
										</a>
									</li>
									<li className='list-group-item'>
										<a href='https://quiz.singlelogin.io' className='list-group-item-link'>
											Certification Prep
										</a>
									</li>
									<li className='list-group-item'>
										<a
											href='https://booking.singlelogin.io'
											className='list-group-item-link'
										>
											Book a Mentor
										</a>
									</li>
									<li className='list-group-item'>
										<a
											href='https://attendance.singlelogin.io'
											className='list-group-item-link'
										>
											Attend Live Class
										</a>
									</li>
								
								</ul>
							</div>
						</div>
						<div className='col-lg-2 col-md-4 col-6'>
							<div className='mb-4'>
								<h4 className='mb-4 text-white '>Follow Us</h4>
								<ul className='list-unstyled list-group'>
									<li className='list-group-item'>
										<a href='/' className='list-group-item-link'>
											Instagram
										</a>
									</li>
									<li className='list-group-item'>
										<a href='/' className='list-group-item-link'>
											{' '}
											Facebook
										</a>
									</li>
									<li className='list-group-item'>
										<a href='/' className='list-group-item-link'>
											{' '}
											LinkedIn
										</a>
									</li>
									 
									<li className='list-group-item'>
										<a href='/' className='list-group-item-link'>
											Twitter
										</a>
									</li>
								</ul>
							</div>
						</div>
						<div className='col-lg-4 col-md-8 col-12'>
							<div className='mb-4'>
								<h4 className='mb-4 text-white'>Subscribe to our newsletter</h4>
								<div>
									<p>
										Subscribe to get notified daily new motivational &
										inspiration tips.
									</p>
									<form>
										<div className='mb-3'>
											<input
												type='email'
												className='form-control border-white'
												placeholder='Email Address'
												required
											/>
										</div>
										<button type='submit' className='btn btn-primary'>
											Subscribe
										</button>
									</form>
								</div>
							</div>
						</div>
						<div className='col-md-12 text-center'>
							<div className='mt-8'>
								<ul className='list-inline'>
									<li className='list-inline-item'>
										© {new Date().getFullYear()} The Blackforce, Inc. <br />
										Call us on +1 (416) 628 8474 | email us at{' '}
										<a href='mailto:training@singlelogin.io'>
											training@blackforce.ca
										</a>{' '}
										| Visit us at Waterloo, Ontario, Canada
									</li>
									<li className='list-inline-item'>
										{' '}
										Designed & Developed with Manifest LMS powered by{' '}
										<a href='https://singlelogin.io'>SingleLogin.io</a>
									</li>
									{/* <li className='list-inline-item'>
										<a href='/' className='text-reset'>
											{' '}
											Privacy Policy{' '}
										</a>
									</li>
									<li className='list-inline-item'>
										<a href='/' className='text-reset'>
											{' '}
											Terms
										</a>
									</li> */}
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
