import { createSlice } from '@reduxjs/toolkit'

const initialPagesState = {
	listLoading: false,
	actionsLoading: false,
	totalCount: 0,
	entities: [],
	lastError: null,
}
export const callTypes = {
	list: 'list',
	action: 'action',
}

export const pagesSlice = createSlice({
	name: 'pages',
	initialState: initialPagesState,
	reducers: {
		catchError: (state, action) => {
			state.error = action.payload.error
			if (action.payload.callType === callTypes.list) {
				state.listLoading = false
			} else {
				state.actionsLoading = false
			}
		},
		startCall: (state, action) => {
			state.error = null
			if (action.payload.callType === callTypes.list) {
				state.listLoading = true
			} else {
				state.actionsLoading = true
			}
		},
		// findPages
		pagesFetched: (state, action) => {
			const { entities } = action.payload
			state.listLoading = false
			state.error = null
			state.entities = entities
		},
		// updatePage
		pageUpdated: (state, action) => {
			state.error = null
			state.actionsLoading = false
			state.entities = state.entities.map((entity) => {
				if (
					entity.page === action.payload.page.page &&
					entity.name === action.payload.page.name
				) {
					entity = action.payload.page
				}
				return entity
			})
		},
		// createCourse
		pageCreated: (state, action) => {
			state.actionsLoading = false
			state.error = null
			state.entities.push(action.payload.page)
		},
	},
})
