/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Link } from "react-router-dom";

export function Nav() {
  const { isAuthorized, role_level, userName, logo, settings, subscriptions } =
    useSelector(
      ({ auth, settings, business, subscriptions }) => ({
        isAuthorized: auth.user != null,
        role_level: auth.user?.role_level,
        userName: auth.user?.user_name,
        settings: settings.entities,
        logo: business.entities.company_logo,
        subscriptions: subscriptions.mySubs,
      }),
      shallowEqual
    );
  return (
    <div className="header fixed-top border-top-3 border-top border-primary header-collapse">
      {/* <!-- navigation start --> */}
      <div className="container-lg">
        <nav className="navbar navbar-expand-lg navbar-default">
          <Link className="" to="/">
            {logo ? (
              <img src={logo} alt="" style={{ height: "40px" }} />
            ) : (
              <img
                src="/assets/images/logo-white.png"
                alt=""
                style={{ height: "60px" }}
              />
            )}
          </Link>
          <button
            className="navbar-toggler collapsed"
            type="button"
            data-toggle="collapse"
            data-target="#navbar-default"
            aria-controls="navbar-default"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="icon-bar top-bar mt-0"></span>
            <span className="icon-bar middle-bar"></span>
            <span className="icon-bar bottom-bar"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbar-default">
            <button
              className="navbar-toggler collapsed"
              type="button"
              data-toggle="collapse"
              data-target="#navbar-default"
              aria-controls="navbar-default"
              aria-expanded="false"
              aria-label="Toggle navigation"
              id="closeNav"
            >
              <i className="fas fa-times"></i>
            </button>
            <ul
              className="navbar-nav ml-auto mr-lg-3 "
              onClick={() => {
                const ele = document.getElementById("navbar-default");
                if (ele.classList.contains("show"))
                  document.getElementById("closeNav").click();
              }}
            >
              <li className="nav-item">
                <Link className="nav-link" id="menu-1" to="/program">
                  Our Programs
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" id="menu-1" to="/course">
                  Our Courses
                </Link>
              </li>

              <li className="nav-item">
                <Link className="nav-link" id="menu-1" to="/team">
                  BlackForce Team
                </Link>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  id="menu-1"
                  href="https://salespage.blackforce.ca/cohort/canada"
                >
                  Pricing
                </a>
              </li>
              <li className="nav-item dropdown">
                <Link className="nav-link" id="menu-1" to="/contact">
                  Contact Us
                </Link>
              </li>
              {!isAuthorized ? (
                <>
                  <li className="nav-item dropdown">
                    <Link className="nav-link" id="menu-1" to="/support">
                      Support
                    </Link>
                  </li>
                  <div className="header-btn mr-2">
                    <Link to="/auth/login" className="btn btn-primary btn-sm ">
                      Sign in
                    </Link>
                  </div>
                  <div className="header-btn ">
                    <a href="#register" className="btn btn-secondary btn-sm ">
                      Register Now
                    </a>
                  </div>
                </>
              ) : (
                <>
                  <li className="nav-item dropdown ">
                    <Link
                      className="nav-link dropdown-toggle"
                      id="menu-1"
                      to="/library"
                    >
                      My Learning
                    </Link>
                  </li>
                  {/* {subscriptions.length ? (
                    <>
                      <li className="nav-item dropdown ">
                        <Link
                          className="nav-link dropdown-toggle"
                          id="menu-1"
                          to="/grader"
                        >
                          Submit Project
                        </Link>
                      </li>
                      <li className="nav-item dropdown ">
                        <Link
                          className="nav-link dropdown-toggle"
                          id="menu-1"
                          to="/jobs"
                        >
                          Job Portal
                        </Link>
                      </li>
                    </>
                  ) : (
                    ""
                  )} */}
                  {role_level <= 4 && settings.account_switch && (
                    <li className="nav-item dropdown ">
                      <div className="header-btn ">
                        <a href="/teach/" className="btn btn-primary btn-sm ">
                          Instructor
                        </a>
                      </div>
                    </li>
                  )}

                  <li className="nav-item dropdown ">
                    {/* <Link className='nav-link dropdown-toggle' id='menu-1' to='/'>
                  My profile
                </Link> */}
                    <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      id="menu-2"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      data-display="static"
                      role="menuitem"
                    >
                      {isAuthorized && userName}
                    </a>
                    <ul
                      className="dropdown-menu  dropdown-menu-right dropdown-menu-xl-left  dropdown-menu-arrow "
                      aria-labelledby="menu-2"
                    >
                      <li className="dropdown-submenu ">
                        <Link
                          className="dropdown-list-group-item"
                          to="/profile"
                        >
                          <div className="d-flex align-items-center">
                            <div>
                              <h4 className="h5">Profile</h4>
                            </div>
                          </div>
                        </Link>
                      </li>
                      {["subscription", "hybrid"].includes(
                        settings.payment_mode
                      ) && (
                        <li className="dropdown-submenu ">
                          <Link
                            className="dropdown-list-group-item"
                            to="/profile/subscription"
                          >
                            <div className="d-flex align-items-center">
                              <div>
                                <h4 className="h5">Subscription</h4>
                              </div>
                            </div>
                          </Link>
                        </li>
                      )}
                      {/* <li className='dropdown-submenu '>
												<Link className='dropdown-list-group-item' to='/cart'>
													<div className='d-flex align-items-center'>
														<div>
															<h4 className='h5'>
																Cart{' '}
																<span className='badge badge-danger'>
																	{cartItems.length}
																</span>
															</h4>
														</div>
													</div>
												</Link>
											</li> */}
                      {/* <li className='dropdown-submenu '>
												<Link
													className='dropdown-list-group-item dropdown-toggle'
													to='/'
												>
													<div className='d-flex align-items-center'>
														<div>
															<h4 className='h5'>Settings</h4>
														</div>
													</div>
												</Link>
											</li> */}
                      <li className="dropdown-submenu ">
                        <a href="/logout" className="dropdown-list-group-item">
                          <div className="d-flex align-items-center">
                            <div>
                              <h4 className="h5">Logout</h4>
                            </div>
                          </div>
                        </a>
                      </li>
                    </ul>
                  </li>
                </>
              )}
            </ul>
            {/* <div className='header-btn '>
              <a href='/instructor' className='btn btn-primary btn-sm '>
                Instructor
              </a>
            </div> */}
          </div>
        </nav>
      </div>
      {/* <!-- navigation close --> */}
    </div>
  );
}
