import { createSlice } from '@reduxjs/toolkit'

const initialLandingsState = {
	listLoading: false,
	actionsLoading: false,
	entities: {},
	landingForEdit: undefined,
	lastError: null,
}
export const callTypes = {
	list: 'list',
	action: 'action',
}

export const landingSlice = createSlice({
	name: 'landings',
	initialState: initialLandingsState,
	reducers: {
		catchError: (state, action) => {
			state.error = action.payload.error
			if (action.payload.callType === callTypes.list) {
				state.listLoading = false
			} else {
				state.actionsLoading = false
			}
		},
		startCall: (state, action) => {
			state.error = null
			if (action.payload.callType === callTypes.list) {
				state.listLoading = true
			} else {
				state.actionsLoading = true
			}
		},
		// findLandings
		landingFetched: (state, action) => {
			const { data } = action.payload
			state.listLoading = false
			state.error = null
			state.entities = data
		},
	},
})
