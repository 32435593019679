import * as requestFromServer from './settingsCrud'
import { settingsSlice, callTypes } from './settingsSlice'

const { actions } = settingsSlice

export const fetchBusinessSettings = () => async (dispatch) => {
	try {
		const data = await requestFromServer.fetchBusinessSettings()
		dispatch(actions.businessSettingsFetched({ data }))
	} catch (error) {
		dispatch(actions.catchError({ error, callType: callTypes.list }))
		throw error
	}
}
