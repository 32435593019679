import { createSlice } from '@reduxjs/toolkit';

const initialSettingsState = {
  listLoading: false,
  actionsLoading: false,
  lastError: null,
  entities: {},
  paymentMode: 'pay_per_course',
  currency: '$',
  account_switch: true,
  paystack_public_key: '',
  businessSlugs: [],
};
export const callTypes = {
  list: 'list',
  action: 'action',
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState: initialSettingsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    businessSettingsFetched: (state, action) => {
      state.entities = action.payload.data;
      state.error = null;
    },
    businessSlugsFetched: (state, action) => {
      state.businessSlugs = action.payload.data;
      state.error = null;
      state.listLoading = false;
    },
  },
});
