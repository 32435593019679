import { createSlice } from '@reduxjs/toolkit'

const initialEnrolmentState = {
	listLoading: false,
	actionsLoading: false,
	totalCount: 0,
	entities: [],
	cohorts: [],
	lastError: null,
}
export const callTypes = {
	list: 'list',
	action: 'action',
}

export const enrolmentSlice = createSlice({
	name: 'enrolment',
	initialState: initialEnrolmentState,
	reducers: {
		catchError: (state, action) => {
			state.error = `${action.type}: ${action.payload.error}`
			if (action.payload.callType === callTypes.list) {
				state.listLoading = false
			} else {
				state.actionsLoading = false
			}
		},
		startCall: (state, action) => {
			state.error = null
			if (action.payload.callType === callTypes.list) {
				state.listLoading = true
			} else {
				state.actionsLoading = true
			}
		},
		enrolmentsFetched: (state, action) => {
			const { totalCount, entities } = action.payload
			state.listLoading = false
			state.entities = entities
			state.totalCount = totalCount
			state.error = null
		},
		cohortsFetched: (state, action) => {
			const { entities } = action.payload
			state.listLoading = false
			state.cohorts = entities.sort((a, b) => a.id - b.id)
			state.error = null
		},
		enrolmentFetched: (state, action) => {
			state.listLoading = false
			state.enrolment = action.payload.data
			state.error = null
		},
		paymentModeFetched: (state, action) => {
			state.paymentMode = action.payload.paymentMode
			state.error = null
		},
		// createEnrolment
		enrolmentCreated: (state, action) => {
			state.actionsLoading = false
			state.error = null
			state.entities.push(action.payload.data)
		},
		slugsFetched: (state, action) => {
			const { totalCount, entities } = action.payload
			state.listLoading = false
			state.slugs = entities
			state.slugTotalCount = totalCount
			state.error = null
		},
		// updateStatus
		lessonStatusUpdated: (state, action) => {
			state.error = null
			state.actionsLoading = false
			state.enrolment.curriculums = state.enrolment.curriculums.map(
				(curriculum) => {
					curriculum.lessons.map((lesson) => {
						if (lesson.id === action.payload.lesson_id) {
							lesson.status = true
						}
						return lesson
					})
					return curriculum
				}
			)
		},
	},
})
