import { createSlice } from '@reduxjs/toolkit'

const initialSubscriptionsState = {
	listLoading: false,
	actionsLoading: false,
	totalCount: 0,
	entities: [],
	mySubs: [],
	enrolments: [],
	subscriptionForEdit: undefined,
	lastError: null,
}
export const callTypes = {
	list: 'list',
	action: 'action',
}

export const subscriptionsSlice = createSlice({
	name: 'subscriptions',
	initialState: initialSubscriptionsState,
	reducers: {
		catchError: (state, action) => {
			state.error = action.payload.error
			if (action.payload.callType === callTypes.list) {
				state.listLoading = false
			} else {
				state.actionsLoading = false
			}
		},
		startCall: (state, action) => {
			state.error = null
			if (action.payload.callType === callTypes.list) {
				state.listLoading = true
			} else {
				state.actionsLoading = true
			}
		},
		// findSubscriptions
		plansFetched: (state, action) => {
			const { totalCount, entities } = action.payload
			state.listLoading = false
			state.error = null
			state.entities = entities
			state.totalCount = totalCount
		},
		// findSubscriptions
		mySubscriptionsFetched: (state, action) => {
			const { entities } = action.payload
			state.listLoading = false
			state.error = null
			state.mySubs = entities
		},
		// findSubscriptions
		myEnrolmentsFetched: (state, action) => {
			const { entities } = action.payload
			state.listLoading = false
			state.error = null
			state.enrolments = entities
		},
		// createSubscription
		subscriptionCreated: (state, action) => {
			state.actionsLoading = false
			state.error = null
			state.entities.push(action.payload.data)
		},
		// updateSubscription
		subscriptionUpdated: (state, action) => {
			state.error = null
			state.actionsLoading = false
			state.entities = state.entities.map((entity) => {
				if (entity.id === action.payload.subscription.id) {
					return action.payload.subscription
				}
				return entity
			})
		},
		// updateSubscription
		enrolmentUpdated: (state, action) => {
			state.error = null
			state.actionsLoading = false
			state.enrolments = state.enrolments.map((entity) => {
				if (entity.id === action.payload.id) {
					entity.program = action.payload.program
				}
				return entity
			})
		},
		// updateStatus
		statusUpdated: (state, action) => {
			state.error = null
			state.actionsLoading = false
			state.entities = state.entities.map((entity) => {
				if (entity.id === action.payload.id) {
					entity.status = action.payload.status
				}
				return entity
			})
		},
		// deleteSubscription
		subscriptionDeleted: (state, action) => {
			state.error = null
			state.actionsLoading = false
			state.entities = state.entities.filter(
				(el) => el.id !== action.payload.id
			)
		},
		subscribed: (state, action) => {
			state.error = null
			state.actionsLoading = false
			state.profile.subscription_id = action.payload.subscription_id
		},
	},
})
