import * as requestFromServer from './subscriptionsCrud'
import { subscriptionsSlice, callTypes } from './subscriptionsSlice'
import { profileSlice, callTypes as profileCallTypes } from '../profileSlice'

const { actions } = subscriptionsSlice
const { subscribed, startCall } = profileSlice.actions

export const fetchAllPlans = (queryParams) => async (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.list }))
	try {
		const response = await requestFromServer.getAllPlans(queryParams)
		dispatch(actions.plansFetched({ ...response }))
	} catch (error) {
		dispatch(actions.catchError({ error, callType: callTypes.list }))
		throw error
	}
}
export const fetchAllMySubscriptions = (queryParams) => async (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.list }))
	try {
		const response = await requestFromServer.getAllMySubscriptions(queryParams)
		dispatch(actions.mySubscriptionsFetched({ ...response }))
	} catch (error) {
		dispatch(actions.catchError({ error, callType: callTypes.list }))
		throw error
	}
}
export const fetchAllMyEnrolments = (queryParams) => async (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.list }))
	try {
		const response = await requestFromServer.getAllMyEnrolments(queryParams)
		dispatch(actions.myEnrolmentsFetched({ ...response }))
	} catch (error) {
		dispatch(actions.catchError({ error, callType: callTypes.list }))
		throw error
	}
}
export const subscribe = (subscription_data) => async (dispatch) => {
	dispatch(startCall({ callType: profileCallTypes.action }))
	try {
		await requestFromServer.subscribe(subscription_data)
		dispatch(subscribed({ subscription_id: subscription_data.id }))
	} catch (error) {
		dispatch(actions.catchError({ error, callType: callTypes.list }))
		throw error
	}
}
export const updateEnrolment = (data) => async (dispatch) => {
	dispatch(startCall({ callType: profileCallTypes.action }))
	try {
		await requestFromServer.updateEnrolment(data)
		dispatch(actions.enrolmentUpdated(data))
	} catch (error) {
		dispatch(actions.catchError({ error, callType: callTypes.list }))
		throw error
	}
}
