import axios from 'axios'

export const MICROSOLUTIONS_URL =
	process.env.REACT_APP_MICROSOLUTIONS_URL + '/auth'
export const GET_USER_BY_ACCESSTOKEN_URL = `${process.env.REACT_APP_API_URL}/verify_token`

export function login_using_refresh_token(data) {
	return axios.post(MICROSOLUTIONS_URL + '/login_using_refresh_token', data)
}

export async function switchBusiness(data) {
	return await axios.post(MICROSOLUTIONS_URL + '/login_with_business_token', {
		...data,
		base_url: process.env.REACT_APP_API_BASE_URL,
	})
}

export async function login(data) {
	return await axios.post(
		MICROSOLUTIONS_URL + '/login_with_username_and_password',
		{ ...data, business_id: 23, base_url: process.env.REACT_APP_API_BASE_URL }
	)
}

export async function getUserByToken() {
	return await axios.get(GET_USER_BY_ACCESSTOKEN_URL)
}
