import { createSlice } from '@reduxjs/toolkit'

const initialProfileState = {
	listLoading: false,
	actionsLoading: false,
	totalCount: 0,
	entities: [],
	tutors: [],
	lastError: null,
	profile: {},
	authorProfile: {},
}
export const callTypes = {
	list: 'list',
	action: 'action',
}

export const profileSlice = createSlice({
	name: 'profile',
	initialState: initialProfileState,
	reducers: {
		catchError: (state, action) => {
			state.error = `${action.type}: ${action.payload.error}`
			if (action.payload.callType === callTypes.list) {
				state.listLoading = false
			} else {
				state.actionsLoading = false
			}
		},
		startCall: (state, action) => {
			state.error = null
			if (action.payload.callType === callTypes.list) {
				state.listLoading = true
			} else {
				state.actionsLoading = true
			}
		},
		tutorsFetched: (state, action) => {
			const { entities } = action.payload
			state.listLoading = false
			state.tutors = entities
			// state.totalCount = totalCount;
			state.error = null
		},
		profilesFetched: (state, action) => {
			const { totalCount, entities } = action.payload
			state.listLoading = false
			state.entities = entities
			state.totalCount = totalCount
			state.error = null
		},
		profileFetched: (state, action) => {
			state.listLoading = false
			state.profile = action.payload.data
			state.error = null
		},
		subscribed: (state, action) => {
			state.actionsLoading = false
			state.profile.subscription_id = action.payload.subscription_id
			state.error = null
		},
		authorProfileFetched: (state, action) => {
			state.listLoading = false
			state.authorProfile = action.payload.profile
			state.error = null
		},
	},
})
